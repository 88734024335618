import React, { Component, PureComponent } from 'react';
import {NavLink} from 'react-router-dom'

import './index.scss'


import Catering from './Catering'

export const pages = [
  {path: "/", label: "Order"},
  {path: "/catering", label: "Catering", component: Catering},
]

export class SiteNav extends PureComponent {
  render() {
    const pages = [
      {path: "/", label: "Order"},
      {path: "/catering", label: "Catering"},
    ]
    return (
    <nav className="pages-nav">
      <ul>
        {pages.map(({path, label, external}) => (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        ))}
      </ul>
    </nav>
  )}
}



export class Header extends PureComponent {
  render() {
    return (
      <div className="landing-page-wrapper">
        <div className="landing-page">

          <div className="text">
            <div className="contact">


            </div>
            {/* <p>Straight from Hong Kong. Best in LA.</p> */}
            {/* <p>Vist Us -></p> */}
            <p>Start your togo or delivery order below</p>
            <p><a href="tel:+18185060116">818-506-0116</a></p>

          </div>


        </div>
      </div>
    );
  }
}


export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4,5].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (minutes >= 30) && (hours < 15)
  ) || (
    (hours >= 12) && (hours < 15)
  )
  const showLunchMenu = isWeekday && isLunch

  const isLunchSubmenu = tag.indexOf('lunch') !== -1
  const isDinnerSubmenu = tag.indexOf('dinner') !== -1

  if (isLunchSubmenu && showLunchMenu) { return true }
  if (isDinnerSubmenu && !showLunchMenu) { return true }

  return false
}
